.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.admin-wrapper .layout {
    min-width: unset;
}

.admin-wrapper #main-content {
    width: 100vw;
}

.list-admin-wrapper .MuiPaper-root {
    overflow-x: auto;
}

.search-input-width {
    width: 470px;
}

.search-input-xs {
    width: 210px;
}

.search-input-sm {
    width: 350px;
}

.search-input-md {
    width: 680px;
}

.MuiTabs-indicator {
    display: none;
}
  
.MuiTab-root.Mui-selected::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(79, 60, 201);
}

.RaSidebar-docked {
    max-width: 245px;
}

.RaSimpleFormIterator-add .MuiSvgIcon-root {
    font-size: 36px;
}
.RaSimpleFormIterator-clear {
    display: none;
}

.RaSimpleFormIterator-action button[aria-label="Move up"],
.RaSimpleFormIterator-action button[aria-label="Move down"] {
  display: none;
}

.RaSimpleFormIterator-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1600px;
    overflow: hidden;
}

.RaSimpleFormIterator-line {
    border-bottom: none !important;
}
  
.array-input-item {
    padding: 2px;
    width: 330px;
}

.RaList-main {
    display: flex;
    width: 100%;
}

.datagrid-content .RaDatagrid-tableWrapper {
    overflow-x: auto;
    max-height: calc(125vh - 135px);
}

.toolbar-style {
    position: absolute !important;
    left: 0px;
    right: 0px;
    z-index: 3;
    color: rgb(79, 60, 201);
    justify-content: space-between;
    background-color: rgb(226, 223, 246);
    min-height: 55px !important;
    height: 55px;
    transform: translateY(-48px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
